.event-group-names-section {
	width: 100%;
	max-width: 1450px;
	margin-top: 30px;

	h3 {
		font-size: 16px;
		margin-bottom: 15px;
		color: #333;
	}

	h4 {
		font-size: 15px;
		margin-top: 25px;
		margin-bottom: 10px;
		color: #333;
	}

	.event-section-description {
		margin-bottom: 15px;
		color: #666;
		font-size: 14px;
	}

	.event-status-indicator {
		padding: 12px 15px;
		margin-bottom: 20px;
		background-color: #f5f5f5;
		border-left: 4px solid #ccc;
		border-radius: 0 4px 4px 0;
		font-weight: 500;
	}

	.event-loading {
		padding: 15px;
		background-color: #f9f9f9;
		border-radius: 4px;
		text-align: center;
		color: #666;
	}

	.event-golf-days-section,
	.event-masters-days-section,
	.event-club-hire-section,
	.event-transfer-days-section {
		background-color: #f9f9f9;
		border-radius: 4px;
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid #eee;

		h4 {
			margin: 0 0 10px;
			font-size: 17px;
			font-weight: 500;
		}

		.event-masters-days-all-header,
		.event-golf-days-all-header,
		.event-club-hire-all-header,
		.event-transfer-days-all-header {
			font-size: 14px;
		}
	}

	.event-golf-days-all,
	.event-masters-days-all,
	.event-club-hire-all,
	.event-transfer-days-all {
		display: flex;
		flex-direction: column;
		margin-bottom: 10px;

		.event-golf-days-all-header,
		.event-masters-days-all-header,
		.event-club-hire-all-header,
		.event-transfer-days-all-header {
			margin-bottom: 10px;
		}

		.event-golf-days-all-checkboxes,
		.event-masters-days-all-checkboxes,
		.event-club-hire-all-checkboxes,
		.event-transfer-days-all-checkboxes {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			align-items: center;

			.ui-checkbox {
				margin-bottom: 5px;
			}

			.event-club-hire-hand-selector {
				display: flex;
				align-items: center;
				gap: 8px;

				label {
					font-size: 14px;
					color: #555;
				}

				select {
					padding: 6px 10px;
					border: 1px solid #ddd;
					border-radius: 4px;
					font-size: 14px;
					background-color: #fff;
					width: 100px;
				}
			}
		}
	}

	.event-group-names-table-container {
		margin-bottom: 20px;
		overflow-x: auto;
		border-radius: 4px;
	}

	.event-group-names-table {
		width: 100%;
		border-collapse: collapse;

		th {
			background-color: #f2f2f2;
			padding: 12px 15px;
			text-align: left;
			font-weight: 500;
			color: #333;
			white-space: nowrap;
			font-size: 14px;
		}

		td {
			padding: 10px;
			border-bottom: 1px solid #eee;
			vertical-align: middle;

			.ui-input-wrapper {
				margin: 0;
			}
		}

		.event-golf-days-cell,
		.event-masters-days-cell,
		.event-club-hire-cell,
		.event-transfer-days-cell {
			.event-days-checkboxes {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.ui-checkbox {
					margin-bottom: 0;

					label {
						font-size: 13px;
					}
				}
			}
		}

		.event-club-hire-cell,
		.event-transfer-status-cell {
			text-align: center;

			.ui-checkbox {
				margin-bottom: 0;
			}

			.event-club-hire-options,
			.event-transfer-status-options {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 8px;
			}

			.event-club-hire-hand,
			.event-transfer-reference {
				select,
				.ui-input-wrapper {
					padding: 4px 8px;
					border: 1px solid #ddd;
					border-radius: 4px;
					font-size: 13px;
					background-color: #fff;
					width: 100px;
				}
			}
		}

		tr:last-child td {
			border-bottom: none;
		}

		tr:hover {
			background-color: #f9f9f9;
		}

		.event-no-data {
			text-align: center;
			padding: 20px;
			color: #888;
			font-style: italic;
		}

		.event-delete-button {
			background-color: #f44336;
			color: white;

			&:hover {
				background-color: #d32f2f;
			}
		}
	}

	.event-button-group {
		display: flex;
		gap: 15px;
		margin-top: 20px;

		button {
			min-width: 120px;
		}
	}
}

// Responsive adjustments
@media (max-width: 768px) {
	.event-group-names-section {
		.event-golf-days-all,
		.event-masters-days-all,
		.event-club-hire-all,
		.event-transfer-days-all {
			.event-golf-days-all-checkboxes,
			.event-masters-days-all-checkboxes,
			.event-club-hire-all-checkboxes,
			.event-transfer-days-all-checkboxes {
				flex-direction: column;
				gap: 5px;
			}
		}

		.event-group-names-table {
			th,
			td {
				padding: 8px;
			}

			.event-golf-days-cell,
			.event-masters-days-cell,
			.event-club-hire-cell,
			.event-transfer-days-cell {
				.event-days-checkboxes {
					.ui-checkbox {
						label {
							font-size: 12px;
						}
					}
				}
			}
		}

		.event-button-group {
			flex-direction: column;
			gap: 10px;

			button {
				width: 100%;
			}
		}
	}
}
