@import "../../assets/styles/vars.scss";

.locked-margins-message {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: -10px;
	margin-bottom: 20px;

	&.margins-locked {
		p {
			background: $amberBG;
			color: $amberFG;
		}
	}

	p {
		gap: 8px;
		display: flex;
		padding: 9px 11px;
		width: fit-content;
		align-items: center;
		border-radius: 6px;
		font-size: 15px;
		background: $positiveBG;
		color: $positiveFG;

		svg {
			height: 16px;
			width: auto;
		}
	}
}

.booking-table {
	&:first-of-type {
		tbody {
			tr {
				&:first-of-type {
					td {
						.table-room-lower-border {
							display: none;
						}
					}
				}
			}
		}
	}

	.seperator {
		padding: 10px 0;
		background: 2px solid #eff2f5;
	}

	.partner-table-title {
		font-size: 20px;
		font-weight: 500;
	}

	tbody {
		tr {
			td {
				border-bottom: 10px solid #fff;
				border-right: 10px solid #fff;
				vertical-align: top;

				small {
					font-size: 14px;
					color: $headingColorLight;
				}

				&:first-child {
					width: 225px;
					line-height: 35px;
				}

				&:nth-child(2) {
					width: 345px;
				}

				&.vertical-center,
				&:nth-child(3) {
					vertical-align: middle;
				}

				&.table-data-button {
					border-top: 30px solid #fff;
				}

				&.text-block {
					vertical-align: middle;

					p {
						font-size: 15px;
						line-height: 1.55em;
						margin-top: 6px;
					}
				}

				.view-atol-certificate {
					display: block;
					color: $primary;
					margin-top: 8px;
					font-size: 14px;
					font-weight: 500;
				}

				.table-data-split {
					&.cols-2 {
						display: grid;
						grid-gap: 15px;
						grid-template-columns: 145px 1fr;

						&.weighted {
							grid-template-columns: 125px 1fr;

							.table-data-column {
								&:first-child {
									.ui-input-wrapper {
										flex: 0 0 75px;
									}
								}

								&:last-child {
									.ui-input-wrapper {
										flex: 0 0 120px;
									}
								}
							}
						}

						&.weighted-style2 {
							grid-template-columns: 165px 1fr;
						}
					}

					.table-data-column {
						display: flex;
						align-items: center;
						justify-content: space-between;

						p {
							font-size: 14px;
						}

						.ui-input-wrapper {
							flex: 0 0 85px;
						}
					}
				}

				.table-room-lower-border {
					height: 2px;
					width: 100%;
					background: #eff2f5;
					margin: 16px 0;
				}
			}
		}
	}
}

.review-atol-cert {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.35em;
	margin: 0 0 12px;
}

.cert-loading {
	display: flex;
	align-items: center;

	p {
		font-size: 15px;
		font-weight: 400;
		color: $headingColor;
	}

	.svg-loading-wrap {
		position: relative;
		height: 12px;
		width: 12px;
		margin-left: 8px;
		transform: rotate(10deg);
		animation-name: loadingSpinner;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		transition: all 500ms cubic-bezier(0.645, 0, 0.34, 1);

		svg {
			position: absolute;
			top: 0;
			right: 0;
			height: 78%;
		}
	}

	@keyframes loadingSpinner {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}

.add-table {
	max-width: 570px;
	display: flex;
	justify-content: flex-end;
	margin: 0 0 20px;
}

.table-sub-heading {
	&.smaller {
		td {
			&:first-child {
				font-size: 16px;
			}
		}
	}

	&.left-align {
		td {
			&:last-child {
				justify-content: flex-start;
			}
		}
	}

	td {
		&:first-child {
			font-size: 18px;
			font-weight: 500;
			color: #585858;
		}

		&:last-child {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			border-right: 5px solid #fff;

			button {
				&:not(:last-child) {
					margin-right: 8px;
				}
			}
		}
	}
}

.booking-table-block {
	display: block;
	max-width: 575px;

	&:not(:last-child) {
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 2px solid #eff2f5;
	}
}

.ui-table {
	&.logs-table {
		position: relative;
		width: calc(100% + 110px);
		left: -55px;

		thead {
			th {
				&:first-child {
					width: 238px;
					padding-left: 55px;
				}

				&:nth-child(2) {
					width: 215px;
				}

				&:nth-child(3) {
					width: 220px;
				}
			}
		}

		tbody {
			tr {
				td {
					&:first-child {
						width: 238px;
						padding-left: 55px;
					}

					&:nth-child(2) {
						width: 215px;
					}

					&:nth-child(3) {
						width: 220px;
					}
				}
			}
		}
	}
}

.direct-booking-extras {
	background: #fff;
	border: 1px solid #ebebeb;
	border-radius: 6px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.06) 0 0 24px;
	padding: 16px 20px;
	max-width: 570px;
	margin-bottom: 20px;

	.direct-booking-extras-header {
		font-size: 18px;
		font-weight: 600;
	}

	.direct-booking-extras-block {
		padding-left: 25px;
		margin-top: 9px;

		.direct-booking-extras-subtitle {
			font-size: 13px;
			color: #8c8b97;
			margin-bottom: 1px;
		}

		.direct-booking-extras-item {
			&:not(:last-of-type) {
				margin-bottom: 10px;
			}

			.direct-booking-extras-line {
				font-size: 14px;
				line-height: 1.25em;
			}
		}
	}
}

.booking__notes-wrapper {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 350px 1fr;
	max-width: 1000px;

	.booking__historical {
		max-height: 65vh;
		overflow: auto;

		.booking__historical-item {
			&:not(.booking__historical-item--empty) {
				padding: 17px 20px;
				border-radius: 5px;
				border: 1px solid #ebebeb;
			}

			&.booking__historical-item--empty {
				font-size: 15px;
				color: #303237;
			}

			&:not(:first-of-type) {
				margin-top: 20px;
			}

			.booking__historical-item__date {
				font-size: 14px;
				font-weight: 600;
			}

			.booking__historical-item__user {
				font-size: 13px;
				color: #8c8b97;
				margin: 2px 0 14px;
			}

			.booking__historical-item__notes {
				font-size: 14px;
				line-height: 1.4em;
				white-space: pre-line;

				&.is-empty {
					font-size: 13px;
					font-style: italic;
					color: #303237;
					opacity: 0.65;

					&:before {
						content: "Notes section set to empty";
					}
				}
			}
		}
	}
}
