.booking__log-item__diff-container {
	table {
		width: 100%;
		margin: 0;
		border: none;
		background: transparent;

		tr {
			background: transparent;
			border: none;

			&.react-diff-1wdmqof-code-fold {
				display: none;
			}
		}

		td {
			width: auto !important;
			padding: 0 15px !important;
			border: none;
			font-family: monospace;
			white-space: pre-wrap;
			vertical-align: top;

			&:first-child {
				padding: 0 15px !important;
			}
		}
	}

	// Style the diff content
	.diff-viewer {
		border: 1px solid #e1e4e8;
		border-radius: 4px;
		margin-top: 5px;
	}

	.diff-line {
		padding: 4px 10px;

		&-number {
			display: none;
		}
	}

	.diff-gutter {
		padding: 4px;
		background: #f6f8fa;
		border-right: 1px solid #e1e4e8;
	}

	.diff-code {
		padding: 4px 10px;
	}
}

.booking__log-item__diff-changes {
	width: 850px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
