@import "../../assets/styles/vars.scss";

.conversions-breakdown-table {
	position: relative;
	width: 100%;

	thead {
		th {
			text-align: left;
			color: $headingColor;
			font-size: 14px;
			line-height: 1em;
			padding-bottom: 8px;
			padding-right: 12px;
			white-space: nowrap;
			cursor: pointer;

			&:hover {
				background-color: rgba(0, 0, 0, 0.05);
			}
		}
	}

	tbody {
		tr {
			position: relative;
			text-align: left;

			&:nth-child(odd) {
				background: #f8f8f8;
			}

			&:nth-child(even) {
				border-top: 1px solid #eff2f5;
				border-bottom: 1px solid #eff2f5;
			}

			td {
				font-size: 15px;
				line-height: 42px;
				white-space: nowrap;
				padding-right: 12px;

				small {
					color: $headingColorLight;

					&.no-value {
						font-size: 14px;
					}
				}
			}
		}
	}
}

.loading-message,
.no-results-message {
	padding: 20px;
	text-align: center;
	color: #666;
	font-style: italic;
}
