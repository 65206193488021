.supplier-invoices-cell {
	min-width: 400px;
	padding: 8px;

	.invoice-table {
		width: 100%;
		border-collapse: collapse;
		font-size: 0.9em;

		th,
		td {
			padding: 4px 8px;
			text-align: left;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}

		th {
			font-weight: 600;
			background-color: rgba(0, 0, 0, 0.02);
		}

		tr:last-child td {
			border-bottom: none;
		}
	}

	.no-value {
		color: #999;
		font-style: italic;
	}
}

small {
	&.has-invoices {
		color: #000 !important;
	}
}
