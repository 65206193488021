@import "../../assets/styles/vars.scss";

.analytics-controls {
	display: flex;
	gap: 20px;
	margin-bottom: 30px;

	.select-wrapper {
		flex: 1;
		max-width: 300px;

		label {
			display: block;
			margin-bottom: 8px;
			font-weight: 500;
		}
	}
}

.date-range-controls {
	display: flex;
	gap: 20px;
	margin-bottom: 30px;

	.date-picker-wrapper {
		flex: 1;
		max-width: 300px;

		label {
			display: block;
			margin-bottom: 8px;
			font-weight: 500;
		}
	}
}

.loading,
.no-selection,
.no-agents-message {
	padding: 40px;
	text-align: center;
	font-size: 16px;
	color: #666;
}

.analytics-content {
	margin-top: 20px;

	.stats-summary {
		display: flex;
		gap: 20px;
		margin-bottom: 30px;

		.stat-card {
			background-color: #f5f5f5;
			border-radius: 8px;
			padding: 16px;
			flex: 1;
			min-width: 150px;
			text-align: center;

			h3 {
				margin: 0 0 8px 0;
				font-size: 14px;
				color: #666;
			}

			.stat-value {
				font-size: 24px;
				font-weight: 600;
				color: #333;
			}

			.stat-subvalue {
				font-size: 14px;
				color: #888;
				margin-top: 4px;
			}
		}
	}

	.charts-container {
		display: flex;
		gap: 30px;
		margin-bottom: 40px;

		.chart-wrapper {
			background-color: #fff;
			border-radius: 8px;
			padding: 20px;

			h3 {
				margin: 0 0 20px;
				font-size: 18px;
				text-align: center;
			}

			&.pie-chart {
				flex: 1;
				max-width: 400px;
			}

			&.bar-chart {
				flex: 2;
			}
		}
	}

	.agent-table-container {
		h3 {
			margin: 0 0 20px;
			font-size: 18px;
		}

		.agent-stats-table {
			width: 100%;

			th {
				text-align: left;
				padding: 12px 15px;
				background-color: #f8f9fa;
				font-weight: 600;
			}

			td {
				padding: 12px 15px;
				border-bottom: 1px solid #eee;
			}

			tr:last-child td {
				border-bottom: none;
			}
		}
	}
}

// Responsive adjustments
@media (max-width: 1024px) {
	.analytics-controls,
	.date-range-controls {
		flex-direction: column;

		.select-wrapper,
		.date-picker-wrapper {
			max-width: 100%;
		}
	}

	.analytics-content {
		.stats-summary {
			flex-wrap: wrap;

			.stat-card {
				min-width: calc(50% - 10px);
			}
		}

		.charts-container {
			flex-direction: column;

			.chart-wrapper {
				&.pie-chart {
					max-width: 100%;
				}
			}
		}
	}
}
