.commission-summary {
	margin-top: 20px;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 5px;

	h2 {
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 600;
	}

	.commission-table {
		width: 100%;
	}
}

.bookings-list {
	margin-top: 20px;

	h2 {
		margin-top: 0;
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 600;
	}

	.bookings-table {
		width: 100%;
	}
}
