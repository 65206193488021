.masters-tickets-container {
	.masters-tickets-header {
		margin-bottom: 20px;

		h2 {
			margin: 0;
			font-size: 24px;
			font-weight: 600;
		}
	}

	.masters-tickets-assign {
		margin-bottom: 30px;

		.masters-tickets-search {
			width: 400px;
			margin-bottom: 10px;
		}

		.masters-tickets-search-results {
			max-height: 200px;
			overflow-y: auto;
			border: 1px solid #e0e0e0;
			border-radius: 4px;
			margin-bottom: 10px;

			.masters-tickets-search-result {
				padding: 10px;
				cursor: pointer;
				border-bottom: 1px solid #e0e0e0;

				&:last-child {
					border-bottom: none;
				}

				&:hover {
					background-color: #f5f5f5;
				}

				&.selected {
					background-color: #e3f2fd;
				}
			}
		}

		.masters-tickets-assign-form {
			display: flex;
			gap: 10px;
			align-items: flex-end;

			.input-container {
				width: 200px;
			}
		}
	}

	.masters-tickets-filters {
		display: flex;
		gap: 20px;
		margin-bottom: 20px;

		.masters-tickets-filter {
			width: 200px;
		}
	}

	.masters-tickets-table {
		.badge {
			&.warning {
				background-color: #ff9800;
				color: white;
			}
		}

		.button {
			padding: 4px 8px;
			font-size: 14px;
		}
	}
}

// Pulse animation for warning badge
@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.badge.warning {
	animation: pulse 2s infinite;
}
