.maybe-course-line {
	display: block;
	margin: 6px 0 0 8px;
}

.condensed-buttons {
	position: relative;
	display: flex;
	gap: 5px;
	justify-content: flex-end;
	top: -3px;

	a {
		margin: 0 !important;
		flex: 0 0 !important;
	}
}
