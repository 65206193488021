.checkbox-stack,
.club-hire-options {
	display: flex;
	flex-direction: column;
	gap: 5px;

	select {
		padding: 4px 8px;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 13px;
		background-color: #fff;
		width: 100px;
	}
}

.buttons-row {
	display: flex;
	gap: 5px;
}
